import { useState, useEffect, useMemo } from "react";
import { getEnv } from "../utils/getEnv";

export const useFetchIframeSrc = (urlToLoad: string, context?: object) => {
  const [loading, setLoading] = useState(true);
  const [iframeSRC, setIframeSRC] = useState<any>(null);
  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get("token");

  const isLocal = getEnv().includes("localhost") || getEnv().includes("3000");
  const baseEnv = getEnv().replace(/https:\/\/next./, "");

  let url = `https://embedded.${baseEnv}/cloak`;

  if (isLocal) {
    url = "https://embedded.red.isbuildingluma.com/cloak";
  }

  const body = useMemo(() => {
    return { url: urlToLoad, access_token: token, context };
  }, [token, urlToLoad, context]);

  useEffect(() => {
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify(body),
    })
      .then(function (res) {
        return res.json();
      })
      .then(function (data) {
        setIframeSRC(data);
      });
  }, [body, url]);

  return {
    iframeSRC,
    loading,
    setLoading,
  };
};
