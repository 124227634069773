import { useFetchIframeSrc } from "../hooks/useFetchIframeSrc";
import { getEnv } from "../utils/getEnv";

interface LumaBroadcastsProps {
  page: string | { url: string };
  context: any;
}

// Listener to log events dispatched by embeds
window.addEventListener("message", (event) => {
  if (event.data.type === "callback") {
    console.log(event.data);
  }
});

const LumaIframe: React.FC<LumaBroadcastsProps> = ({ page, context }) => {
  const { iframeSRC, loading, setLoading } = useFetchIframeSrc(
    `${getEnv()}/embed/${typeof page === "string" ? page : page.url}`,
    context
  );

  const urlParams = new URLSearchParams(window.location.search);
  const embeddedPatientId = urlParams.get("embeddedPatientId");

  return (
    <>
      {loading && <p>Loading...</p>}
      {iframeSRC && (
        <iframe
          sandbox="allow-scripts allow-same-origin allow-downloads allow-forms allow-popups allow-popups-to-escape-sandbox"
          title="Luma Broadcasts"
          src={`${getEnv()}/embed/cloak/${iframeSRC.hash}${
            embeddedPatientId ? `?embeddedPatientId=${embeddedPatientId}` : ""
          }`}
          width="100%"
          height="100%"
          style={{ borderWidth: 0 }}
          onLoad={() => setLoading(false)}
        />
      )}
    </>
  );
};

export default LumaIframe;
